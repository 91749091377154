import React from 'react';

export default function Spinner(props) {
    const style = {
        width: '20px',
        height: '20px',
        borderWidth: '2px'
    }
    return (
        (
            props.loading === false ?
                <span>{props.text}</span> :
                <div className="spinner-border" style={style} role="status">
                    <span className="sr-only"></span>
                </div>
        )
    );
}