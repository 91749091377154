import React from 'react';
import { MDBBtn } from 'mdbreact';
import './style.css';

export default function LoadMoreButton(props) {

    return (
        <div className="load-more-container">
            {
                props.loading ?
                    // <div className="bar2"></div>
                    <div className="white-text">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    :
                    <MDBBtn className="load-more-btn mt-4" size="md" onClick={() => props.cb()} disabled={props.disabled}>{props.text}</MDBBtn>
            }
        </div>
    )
}