import React, { useContext, useEffect, useState } from 'react'
import { RequestorContext } from '../../utils/StrapiRequestor';
import { MDBRow, MDBCol, MDBCard } from 'mdbreact'
// import LoadMoreButton from '../ButtonComponents/LoadMoreButton';

function VideoCardContainer(props) {

    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(3);
    const [isMore, setIsMore] = useState(true);

    const ctx = useContext(RequestorContext);

    const getVideos = () => {
        setLoading(true);
        setTimeout(() => {
            ctx.requestor.videosLatest(skip, take)
                .then(res => res.json())
                .then(data => {
                    let cVideos = videos.concat(data);
                    setVideos(cVideos);
                    if (data.length < take) {
                        setIsMore(false);
                    }
                    let nSkip = skip + take;
                    setSkip(nSkip);
                }).catch(error => { })
                .finally(() => {
                    setLoading(false)
                });
        }, 500);
    }

    useEffect(() => {
        getVideos();
    }, []);

    const cards = videos.map((v, i) =>
        <>
            {
                v.id ?
                    <>
                        <MDBCol key={i} md="6" lg="4">
                            <div className="mt-4 mb-2 rounded-3">
                                <MDBCard className="rounded-3">
                                    <div className="embed-responsive embed-responsive-16by9 rounded-3 white-border">
                                        <iframe title="Embeds Page" className="embed-responsive-item rounded-3" src={v.url}
                                            allowfullscreen="allowfullscreen"
                                            mozallowfullscreen="mozallowfullscreen"
                                            msallowfullscreen="msallowfullscreen"
                                            oallowfullscreen="oallowfullscreen"
                                            webkitallowfullscreen="webkitallowfullscreen"   ></iframe>
                                    </div>
                                </MDBCard>
                            </div></MDBCol>
                    </>
                    : null
            }
        </>
    );

    return (
        <>
            <MDBRow style={{ justifyContent: 'center' }}>
                {cards}
            </MDBRow>
        </>
    );
};

export default VideoCardContainer;