import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBIcon, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact'
import { scrollTo } from '../../App';
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom';
import './style.css'
import { Parallax } from 'react-parallax'
import ReactGA from 'react-ga';


class AboutPage extends React.Component {

    componentDidMount() {
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/property-team');
    }

    render() {
        return (
            <div className="mx-auto" style={{ overflow: "hidden", backgroundColor: 'white', color: 'black' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Our Team | Silverstar Developments</title>
                    {/* <description>Contact us to find out more.</description> */}
                    <link rel="canonical" href="https://www.silverstardevelopments.co.nz/property-team" />
                </Helmet>
                <ScrollToTop />
                <div style={{ backgroundColor: "white" }}>
                    <div className="team-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter mb-2">
                        <div className="header-text">
                            <div className="h1-responsive text-left center secondary-text font-weight-md mb-4 pl-lg-2 pl-2">Meet our team</div>
                            <h1 className="h1-responsive text-left center white-text font-weight-md mb-4 pl-lg-2 pl-2">The property development team</h1>
                        </div>
                    </div>

                    <div className="default-bg pt-5 pb-5">
                        <MDBContainer className="text-left pt-5 pb-2">
                            <MDBCol md="12" className="mx-auto mb-5">
                                <MDBRow>
                                    <MDBCol md="6" className="mx-auto mb-lg-0 mb-5">
                                        <div className="text-center">
                                            <img src={require('../../assets/img/tony.png')} alt="Tony" className="w-100 img-fluid" style={{ maxWidth: "280px" }} />
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="6" className="mx-auto">
                                        <div className="text-center">
                                            <h2 className="h1-responsive mt-3 mb-5 white-text text-center">Our leadership</h2>
                                            <h3 className="mt-4 mb-2 secondary-text">Tony Anderson</h3>
                                            <h5 className="text-uppercase font-weight-md grey-text">Director</h5>
                                            <div className="white-text h6-responsive mt-4 mb-4 text-center">
                                                Tony has been in the construction industry for 35 years building and overseeing the construction of quality-built homes.
                                            </div>
                                            <div className="text-center mt-4 mb-4" style={{ fontSize: '14px' }}>
                                                <a className="white-text mb-2" href="mailto:tony@silverstardevelopments.co.nz"><i className="fa fa-envelope mr-3 white-text" />tony@silverstardevelopments.co.nz</a><br />
                                                <a className="white-text" href="tel:+64212833396"><i className="fa fa-phone mr-3 white-text" />+64 21 283 3396</a>
                                            </div>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBContainer>
                    </div>

                    <div className="default-bg pt-2 pb-5 mt-2">
                        <div className="text-center pt-5 pb-2 pr-lg-5 pl-lg-5 pr-2 pl-2">
                            {/* <MDBIcon icon="users" size="2x" className="mt-4" /> */}
                            <h2 className="h1-responsive mt-3 mb-4 white-text text-center">Our team</h2>
                            <MDBCol md="12" className="mx-auto mb-5">
                                <MDBRow>
                                    <MDBCol sm='12' md="4" lg='3' className="mx-auto mt-5 white-border rounded-3">
                                        <div className="text-center my-5">
                                            <div className="text-center">
                                                <img src={require('../../assets/img/bridget_new.png')} alt="Bridget Perry" className="w-100 img-fluid" style={{ maxWidth: "100%", maxHeight: 300, objectFit: 'contain' }} />
                                            </div>
                                            <h3 className="mt-4 mb-2 secondary-text">Bridget Perry</h3>
                                            <h5 className="text-uppercase font-weight-md grey-text">Sales Consultant</h5>
                                            <div className="text-center mt-3" style={{ fontSize: '14px' }}>
                                                <a className="white-text mb-2" href="tel:+6421402071"><i className="fa fa-phone mr-3 white-text" />+64 21 402 071</a><br />
                                                <a className="white-text" href="mailto:bridget@ere.nz"><i className="fa fa-envelope mr-3 white-text" />bridget@ere.nz</a>
                                            </div>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm='12' md="4" lg='3' className="mx-auto mt-5 white-border rounded-3">
                                        <div className="text-center my-5">
                                            <div className="text-center">
                                                <img src={require('../../assets/img/fiona_new.png')} alt="Fiona Williams" className="w-100 img-fluid" style={{ maxWidth: "100%", maxHeight: 300, objectFit: 'contain' }} />
                                            </div>
                                            <h3 className="mt-4 mb-2 secondary-text">Fiona Williams</h3>
                                            <h5 className="text-uppercase font-weight-md grey-text">Sales Consultant</h5>
                                            <div className="text-center mt-3" style={{ fontSize: '14px' }}>
                                                <a className="white-text mb-2" href="tel:+64272333804"><i className="fa fa-phone mr-3 white-text" />+64 27 233 3804</a><br />
                                                <a className="white-text" href="mailto:fiona@ere.nz"><i className="fa fa-envelope mr-3 white-text" />fiona@ere.nz</a>
                                            </div>
                                        </div>
                                    </MDBCol>
                                    <MDBCol sm='12' md="4" lg='3' className="mx-auto mt-5 white-border rounded-3">
                                        <div className="text-center my-5">
                                            <div className="text-center">
                                                <img src={require('../../assets/img/louise.png')} alt="Louise Phillips" className="w-100 img-fluid" style={{ maxWidth: "100%", maxHeight: 300, objectFit: 'contain' }} />
                                            </div>
                                            <h3 className="mt-4 mb-2 secondary-text">Louise Phillips</h3>
                                            <h5 className="text-uppercase font-weight-md grey-text">Sales Consultant with Bridget Perry</h5>
                                            <div className="text-center mt-3" style={{ fontSize: '14px' }}>
                                                <a className="white-text mb-2" href="tel:+64272443540"><i className="fa fa-phone mr-3 white-text" />+64 27 244 3540</a><br />
                                                <a className="white-text" href="mailto:louise@ere.nz"><i className="fa fa-envelope mr-3 white-text" />louise@ere.nz</a>
                                            </div>
                                        </div>
                                    </MDBCol>

                                    <MDBCol md="5" className="mx-auto mt-5 white-border rounded-3">
                                        <div className="text-center my-5">
                                            <MDBIcon icon="user-alt" size="2x" />
                                            <h3 className="mt-4 mb-2 secondary-text">Rodney Kearney</h3>
                                            <h5 className="text-uppercase font-weight-md grey-text">Designer</h5>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="5" className="mx-auto mt-5 white-border rounded-3">
                                        <div className="text-center my-5">
                                            <MDBIcon icon="user-alt" size="2x" />
                                            <h3 className="mt-4 mb-2 secondary-text">Nicola Broekhuizen</h3>
                                            <h5 className="text-uppercase font-weight-md grey-text">Office Administrator</h5>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </div>
                    </div>

                    <Parallax
                        bgImage={require('../../assets/img/ss2.jpg')}
                        bgImageAlt="silverstar developments"
                        strength={200}
                        className="page-header header-filter clear-filter dark-filter white-text mt-2"
                    >
                        <MDBContainer className="text-left" id="more">
                            <div style={{ zIndex: '2', position: "relative" }}>
                                <h2 className="h2-responsive">We are here to help make buying a new home simple.</h2>
                                <h6 className="h5-responsive mt-3 mb-lg-4">Get in touch with any of our team to find out more.</h6>
                                <MDBBtn color="white" outline onClick={() => this.props.history.push('/contact-us')} >Contact Us</MDBBtn>
                            </div>
                        </MDBContainer>

                        <div style={{ minHeight: '400px' }} />
                    </Parallax>

                </div>
            </div >
        );
    }
}

export default withRouter(AboutPage);