import React from "react";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBNavLink
} from "mdbreact";
import { withRouter, Link } from 'react-router-dom';
import './style.scss'
// import MobileNavOne from "./mn1";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import { RequestorContext } from "../../utils/StrapiRequestor";

export const NAV_LINKS = [
    { type: 'single', name: 'Home', path: '/' },
    { type: 'single', name: 'Property For Sale', path: 'property-for-sale' },
    { type: 'single', name: 'Sold Property', path: 'sold-property' },
    {
        type: 'dropdown', name: 'Discover', children: [
            { name: 'Virtual Tours', path: 'virtual-tours' },
            { name: 'Neighbourhood Guide', path: 'neighbourhood-guide' },
        ]
    },
    {
        type: 'dropdown', name: 'About Us', children: [
            { type: 'single', name: 'About Us', path: 'property-developers' },
            { type: 'single', name: 'Our Team', path: 'property-team' },
        ]
    },
    { type: 'single', name: 'Contact Us', path: 'contact-us' }
]


const MAX_LOGO_HEIGHT = 90;
const MOBILE_MAX_LOGO_HEIGHT = 64;
const MIN_HEIGHT = 65;


class Nav extends React.Component {
    constructor(props) {
        super(props)
        // let scrollTop = window.scrollY;
        // let minHeight = MIN_HEIGHT;
        // let logoHeightBig = Math.max(minHeight, MAX_LOGO_HEIGHT - scrollTop);
        // let logoHeightSmall = Math.max(minHeight, 100 - scrollTop);

        this.state = {
            isTop: true,
            menuOpen: false,
            activeUrl: '/',
            logoHeight: MAX_LOGO_HEIGHT
        };
    }

    componentDidMount() {
        const handler = e => this.setState({ matches: e.matches });
        window.matchMedia("(min-width: 768px)").addListener(handler);

        window.onscroll = () => {
            if (window.scrollY > 100) {
                this.setState({ isTop: false })
            } else {
                this.setState({ isTop: true })
            }
        }
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleScroll);
        this.handleScroll();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    }

    toggleMenu = (e) => {
        if (e)
            e.preventDefault();
        let ele = document.getElementById('mdb-hamburger-menu');
        if (ele) {
            ele.classList.toggle('opened');
            ele.classList.toggle('closed');
        }


        let isOpen = !this.state.menuOpen;
        this.setState({
            menuOpen: isOpen
        });
    }

    updateActiveUrl = (url) => {
        this.props.requestor.toggleCloseFunc();
        if (window.innerWidth < 992) {
            this.toggleMenu();
        }

        this.setState({
            activeUrl: url
        });

        if (url.includes('#')) {
            let urlSplit = url.split('#');
            if (urlSplit.length > 1) {
                let id = urlSplit[urlSplit.length - 1];
                this.scrollTo(id);
            }
        }
    };

    scrollTo(id) {
        let ele = document.getElementById(id);
        if (ele) {
            let top = ele.offsetTop;
            window.scrollTo({
                top: top,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    get maxLogoHeight() {
        if (window.innerWidth < 768) {
            return MOBILE_MAX_LOGO_HEIGHT;
        } else {
            return MAX_LOGO_HEIGHT;
        }
    }

    handleScroll = (event) => {
        if (window.innerWidth < 768) {
            this.setState({
                logoHeight: MOBILE_MAX_LOGO_HEIGHT
            });
        } else {
            let scrollTop = window.scrollY;
            let minHeight = MIN_HEIGHT;
            let logoHeightBig = Math.min(MAX_LOGO_HEIGHT, Math.max(minHeight, MAX_LOGO_HEIGHT - scrollTop));
            let logoHeightSmall = Math.max(minHeight, 100 - scrollTop);

            this.setState({
                logoHeight: window.matchMedia("(min-width: 768px)").matches === true ? logoHeightBig : logoHeightSmall
            });
        }
    }

    render() {
        const links = NAV_LINKS.map((l, i) => {
            if (l.type === 'dropdown') {
                return (
                    <MDBDropdown key={i}>
                        <MDBDropdownToggle className="pt-2" nav caret>
                            <span className="mr-2"><b className="ml-1 white-text">{l.name}</b></span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu>

                            {
                                l.children.map((c, x) => {
                                    return (
                                        <MDBDropdownItem key={x} active={c.path === window.location.pathname} onClick={() => this.updateActiveUrl(c.path)}>
                                            <MDBNavLink to={c.path} className="grey-text" style={{ fontWeight: '800' }}>{c.name}</MDBNavLink>
                                        </MDBDropdownItem>
                                    )
                                })
                            }
                        </MDBDropdownMenu>
                    </MDBDropdown>
                )
            } else {
                return (
                    <MDBNavItem key={i} className="pb-2 pt-2" active={l.path === window.location.pathname} onClick={() => this.updateActiveUrl(l.path)}>
                        <Link to={l.path} style={{ paddingLeft: "15px", paddingRight: "15px" }}><b className="white-text mr-lg-2">{l.name}</b></Link>
                    </MDBNavItem>
                )
            }
        });

        return (
            <>
                {/* <MobileNavOne links={NAV_LINKS} updateActiveUrl={this.updateActiveUrl} /> */}
                <MDBNavbar
                    id="nav"
                    color="primary-color"
                    dark
                    expand="xl"
                    fixed="top"
                    scrolling
                    transparent
                >
                    <MDBNavbarBrand>
                        <img src={require('../../assets/img/logos/navLogo-white.png')} alt="logo" className="pointer" onClick={() => window.location.href = '/'} style={{ height: (this.state.logoHeight + 'px') }} />
                    </MDBNavbarBrand>

                    <div className="mdb-hamburger-menu-container hide-mobile-nav">
                        <a id="mdb-hamburger-menu" className="closed" href="#menu" onClick={(e) => this.toggleMenu(e)}>
                            <div className="hamburger-line top"></div>
                            <div className="hamburger-line middle"></div>
                            <div className="hamburger-line bottom"></div>
                        </a>
                    </div>

                    <MDBCollapse id="navbarCollapse" isOpen={this.state.menuOpen} className={this.state.menuOpen ? 'fade-in' : null} navbar>
                        <MDBNavbarNav right>
                            {links}
                            {
                                (isMobile) ?
                                    <>
                                        <div className="mt-5 text-center">
                                            <a className="ins-ic" href="https://www.instagram.com/silverstar_developments/?hl=en" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram white-text mr-4 mr-lg-4"> </i>
                                            </a>
                                            <a className="fb-ic ml-0" href="https://www.facebook.com/silverstardevelopmentsltd/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook-f white-text mr-4 mr-lg-4"> </i>
                                            </a>
                                            <a className="ins-ic" href="https://www.youtube.com/channel/UCpNRHrDL593o2gy4WeWMP7w" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-youtube white-text"> </i>
                                            </a>
                                        </div>
                                        <hr className="white mt-5 mb-5 d-inline-block mx-auto" style={{ width: "100%", height: "2px" }} />
                                        <div className="text-center">
                                            <img src={require('../../assets/img/logos/navLogo-white.png')} alt="logo" className="pointer" onClick={() => window.location.href = '/'} style={{ width: '160px' }} />
                                        </div>
                                    </> :
                                    <>
                                        <div className="social-container">
                                            <a className="ins-ic" href="https://www.instagram.com/silverstar_developments/?hl=en" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-instagram white-text mr-4 mr-lg-4"> </i>
                                            </a>
                                            <a className="fb-ic ml-0" href="https://www.facebook.com/silverstardevelopmentsltd/" target="_blank" rel="noopener noreferrer">
                                                <i className="fab fa-facebook-f white-text mr-4 mr-lg-4"> </i>
                                            </a>
                                        </div>
                                    </>
                            }
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>
            </>
        );
    }
}

export default withRouter(Nav);