import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBIcon, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom';
import './style.css'
import { axRequest, METHOD_POST } from '../../utils/request';
import { SK, SUCCESS_COLOR, FAILED_COLOR } from '../../utils/constants';
import ReactGA from 'react-ga';


class ContactUsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false,
            sendMessage: '',
            color: '',
            data: {
                key: SK,
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: '',
                extra: ''
            }
        }
    }

    componentDidMount() {
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/contact-us');
    }

    submitHandler = async (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        let inputs = event.nativeEvent.target;
        for (let i = 0; i < inputs.length; i++) {
            let input = inputs[i];

            if (input.name === 'extra')
                continue;

            if (input != null && !input.validity.valid) {
                return;
            }
        }

        let s = this.state;
        s.processing = true;
        s.sendMessage = '';
        this.setState(s);

        let response = await axRequest(null, '/client-mailers', METHOD_POST, this.state.data);
        s = this.state;
        if (response && response.success) {
            s.color = SUCCESS_COLOR;
            s.sendMessage = 'Thanks for your message, we\'ll be in touch soon!'
            this.resetForm();
        } else {
            s.color = FAILED_COLOR;
            s.sendMessage = 'Oops, something went wrong, please try again!!'
        }
        s.processing = false;
        this.setState(s);
    }

    handleChange = (event) => {
        let s = this.state;
        s.data[event.target.name] = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState(s);
    };

    resetForm = () => {
        document.getElementById("contact-form").classList.remove("was-validated");
        let s = this.state;
        s.data = {
            name: '',
            email: '',
            phone: '',
            subject: '',
            message: ''
        };

        this.setState(s);
    }

    render() {
        return (
            <div className="mx-auto">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Contact Us | Silverstar Developments</title>
                    {/* <description>Contact us to find out more.</description> */}
                    <link rel="canonical" href="https://www.silverstardevelopments.co.nz/contact-us" />
                </Helmet>
                <ScrollToTop />
                <div style={{ backgroundColor: "white" }}>
                    <div className="contact-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter mb-2">
                        <div className="header-text">
                            <div className="h1-responsive text-left center secondary-text font-weight-md mb-4 pl-lg-2">Contact Us</div>
                            <h1 className="h1-responsive text-left center white-text font-weight-md mb-4 pl-lg-2">Get in touch with us today</h1>
                        </div>
                    </div>
                    <div id="contact" className="pt-5 pb-5 default-bg">
                        <MDBContainer className="mt-5 mb-5">
                            <MDBAnimation type="fadeIn" className="slow">
                                <MDBRow>
                                    <MDBCol sm="12" md="12" lg="4">
                                        <div className="text-left">
                                            <h1 className="h3-responsive font-weight-md mb-4 mt-3">Contact Details</h1>
                                            <MDBRow>
                                                <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                                    <p>
                                                        <b className="font-quatBold">Phone</b>
                                                    </p>
                                                </MDBCol>
                                                <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                                                    <p>
                                                        <a className="grey-text" href="tel:+64212833396">+64 21 283 3396</a>
                                                    </p>
                                                </MDBCol>
                                                <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                                    <p>
                                                        <b className="font-quatBold">Email</b>
                                                    </p>
                                                </MDBCol>
                                                <MDBCol md="10" lg="10" className="mb-0 m-lr-auto">
                                                    <p>
                                                        <a className="grey-text" href="mailto:sales@silverstardevelopments.co.nz">sales@silverstardevelopments.co.nz</a>
                                                    </p>
                                                </MDBCol>
                                                <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                                    <p>
                                                        <b className="font-quatBold">Postal</b>
                                                    </p>
                                                </MDBCol>
                                                <MDBCol md="10" lg="10" className="mb-0 m-lr-auto grey-text">
                                                    <p>
                                                        PO Box 5648, Papanui, Christchurch
                                                </p>
                                                </MDBCol>
                                                <MDBCol md="2" lg="2" className="mb-0 m-lr-auto">
                                                    <p>
                                                        <b className="font-quatBold">Social</b>
                                                    </p>
                                                </MDBCol>
                                                <MDBCol md="10" lg="10" className="m-lr-auto mb-5 grey-text">
                                                    <a className="ins-ic" href="https://www.instagram.com/silverstar_developments/?hl=en" target="_blank" rel="noopener noreferrer">
                                                        <i className="fab fa-instagram grey-text mr-4 mr-lg-4"> </i>
                                                    </a>
                                                    <a className="fb-ic ml-0" href="https://www.facebook.com/silverstardevelopmentsltd/" target="_blank" rel="noopener noreferrer">
                                                        <i className="fab fa-facebook-f grey-text mr-4 mr-lg-4"> </i>
                                                    </a>
                                                    <a className="ins-ic" href="https://www.youtube.com/channel/UCpNRHrDL593o2gy4WeWMP7w" target="_blank" rel="noopener noreferrer">
                                                        <i className="fab fa-youtube white-text mr-4 mr-lg-4"> </i>
                                                    </a>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                    </MDBCol>
                                    <MDBCol md="12" lg="7" className="text-left mx-auto">
                                        <h1 className="text-left h3-responsive mx-auto mt-2 mb-4 font-weight-md">Send us a message</h1>
                                        <div className="mb-5 h6-responsive">
                                            We appreciate you taking the time to contact us. Please fill out the contact form below and we will be in touch with you shortly.
                                    </div>
                                        <MDBCard style={{ padding: '6%', backgroundColor: 'black', border: '1px solid white' }}>
                                            <div className="text-center mt-3 mb-4">
                                                <MDBIcon far icon="comment" size="3x" className="white-text" />
                                            </div>
                                            <MDBCardBody>
                                                <form id="contact-form" className="needs-validation" onSubmit={this.submitHandler} noValidate>
                                                    {
                                                        this.state.sendMessage && this.state.sendMessage.length > 0 ? (
                                                            <MDBRow>
                                                                <MDBCol
                                                                    style={{ minHeight: '45px', padding: '5px 0px 25px 0px', fontSize: '14px', textAlign: 'center' }}
                                                                >

                                                                    <MDBAnimation type="bounceIn" duration="500ms">
                                                                        <div style={{ padding: '15px 5px 0px 5px', fontWeight: 500, fontSize: '20px', color: this.state.color }}>
                                                                            {this.state.sendMessage}
                                                                        </div>
                                                                    </MDBAnimation>

                                                                </MDBCol>
                                                            </MDBRow>
                                                        ) : null
                                                    }
                                                    <div className="form-group">
                                                        <label htmlFor="name" className="grey-text">
                                                            Your name
                                                </label>
                                                        <input
                                                            type="text"
                                                            id="name"
                                                            name="name"
                                                            className="form-control"
                                                            value={this.state.data.name}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.processing}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Required.
                                                </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="email" className="grey-text">
                                                            Your email
                                                </label>
                                                        <input
                                                            type="email"
                                                            id="email"
                                                            name="email"
                                                            className="form-control"
                                                            value={this.state.data.email}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.processing}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Required.
                                                </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="phone" className="grey-text">
                                                            Your phone number
                                                </label>
                                                        <input
                                                            type="tel"
                                                            id="phone"
                                                            name="phone"
                                                            pattern="^(0\d\d{7}|02\d\d{6,12}|0800\d{5,12})$"
                                                            className="form-control"
                                                            value={this.state.data.phone}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.processing}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Required.
                                                </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="subject" className="grey-text">
                                                            Subject
                                                </label>
                                                        <input
                                                            type="text"
                                                            id="subject"
                                                            name="subject"
                                                            className="form-control"
                                                            value={this.state.data.subject}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.processing}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Required.
                                                </div>
                                                    </div>
                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="message" className="grey-text">
                                                            Your message
                                                </label>
                                                        <textarea
                                                            type="text"
                                                            id="message"
                                                            name="message"
                                                            className="form-control"
                                                            rows="3"
                                                            value={this.state.data.message}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.processing}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Required.
                                                </div>
                                                    </div>
                                                    <div className="form-group" style={{ display: 'none' }}>
                                                        <label htmlFor="message" className="grey-text">
                                                            Extra Info
                                                </label>
                                                        <textarea
                                                            type="text"
                                                            id="extra"
                                                            name="extra"
                                                            className="form-control"
                                                            rows="3"
                                                            value={this.state.data.extra}
                                                            onChange={this.handleChange}
                                                            disabled={this.state.processing}
                                                            required
                                                        />
                                                        <div className="invalid-feedback">
                                                            Required.
                                                </div>
                                                    </div>
                                                    <div className="text-center mt-5 mb-4">
                                                        <MDBBtn
                                                            color="white"
                                                            type="submit"
                                                            outline
                                                            style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto' }}
                                                            disabled={this.state.processing}
                                                        >
                                                            Send message
                                                    {
                                                                !this.state.processing ? null :
                                                                    (
                                                                        <div
                                                                            className="spinner-border"
                                                                            style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                                                                            role="status"
                                                                        >
                                                                            <span className="sr-only">Loading...</span>
                                                                        </div>
                                                                    )
                                                            }
                                                        </MDBBtn>
                                                    </div>
                                                </form>
                                            </MDBCardBody>
                                        </MDBCard>
                                    </MDBCol>
                                </MDBRow>
                            </MDBAnimation>
                        </MDBContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(ContactUsPage);