import moment from 'moment';

export const API_BASE_URL = 'https://admin.xodesoftware.com';
export const SK = '$-xREDUqLCf47@zP';

export const SUCCESS_COLOR = 'rgb(75, 75, 75)';
export const FAILED_COLOR = '#d32f2f';

export const STRAPI_URL = 'https://admin.silverstardevelopments.co.nz';
export const GET = 'GET';
export const POST = 'POST';
export const PUT = 'PUT';
export const DELETE = 'DELETE';
export const CONTACT_MESSAGE_IDENTIFIER = '&dFNPkfPd3kXvyf6dktbPSpQ=MNZY?%gWqcQwu3JsaShsYs-AM?f6T8fYJYrAN^c';

export const IS_LOADING = true;
export const LOADED = false;


export const openHomeDateConverter = (openHomes, firstOnly = false) => {
    let ohTimes = [];
    try {
        openHomes = openHomes.sort((a, b) => {
            let dateA = new Date(a.start);
            let dateB = new Date(b.start);
            return dateA.getTime() > dateB.getTime() ? 1 : -1;
        });
        openHomes = openHomes.filter((oh) => {
            let mDate = moment(oh.start);
            let fullDateStr = mDate.format('YYYY-MM-DD');
            let comparisonTime = moment(fullDateStr + 'T' + oh.endTime)
            return comparisonTime.isAfter()

        });
        ohTimes = openHomes.map((oh, i) => {
            let mDate = moment(oh.start);
            let fullDateStr = mDate.format('YYYY-MM-DD');
            let today = moment().format('YYYY-MM-DD');

            let day = mDate.format('dddd');
            let date = mDate.date();
            let month = mDate.format('MMMM');
            let startTime = mDate.format('h:mm A');
            let endTime = moment(oh.endTime, ['HH:mm']).format('h:mm A');
            if(fullDateStr == today)
                return `Today ${startTime.replace(' ', '').toLowerCase()} - ${endTime.replace(' ', '').toLowerCase()}`;
            else
                return `${day} ${date} ${month.substr(0, 3)} ${startTime.replace(' ', '').toLowerCase()} - ${endTime.replace(' ', '').toLowerCase()}`;
        });
    } catch {
        // nothing to see here
    }
    return ohTimes && ohTimes.length > 0 && firstOnly ? ohTimes[0] : ohTimes;
};

export const openHomeDateConverterMinimal = (openHomes, firstOnly = false) => {
    let ohTimes = [];
    try {
        openHomes = openHomes.sort((a, b) => {
            let dateA = new Date(a.start);
            let dateB = new Date(b.start);
            return dateA.getTime() > dateB.getTime() ? 1 : -1;
        });
        openHomes = openHomes.filter((oh) => {
            let mDate = moment(oh.start);
            let fullDateStr = mDate.format('YYYY-MM-DD');
            let comparisonTime = moment(fullDateStr + 'T' + oh.endTime)
            return comparisonTime.isAfter()

        });
        ohTimes = openHomes.map((oh, i) => {
            let mDate = moment(oh.start);
            let fullDateStr = mDate.format('YYYY-MM-DD');
            let today = moment().format('YYYY-MM-DD');

            let day = mDate.format('ddd');
            let date = mDate.date();
            let month = mDate.format('MMMM');
            let startTime = mDate.format('h:mm A');
            if(fullDateStr == today)
                return `Today ${startTime.replace(' ', '').toLowerCase()}`;
            else
                return `${day} ${date} ${month.substr(0, 3)} ${startTime.replace(' ', '').toLowerCase()}`;
        });
    } catch (e) {
        // nothing to see here
    }
    return ohTimes && ohTimes.length > 0 && firstOnly ? ohTimes[0] : ohTimes;
};