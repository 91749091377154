import React from 'react';
import { MDBAnimation, MDBIcon, MDBRow, MDBCol } from 'mdbreact';
import { openHomeDateConverterMinimal } from '../../utils/constants';
import ReactHtmlParser from 'react-html-parser';
import './style.css';

const ProjectCard = (props) => {
    const suburb = props.suburb && props.suburb.Name ? props.suburb.Name : null;
    const city = props.city && props.city.Name ? ' | ' + props.city.Name : null;
    const openhomes = openHomeDateConverterMinimal(props.openhomes);

    return (
        <div className="card elegant-color-dark divider-margin-sm mb-4" style={{ minHeight: "400px", cursor: 'pointer' }} onClick={() => props.toggleProject(props.id, true)}>
            <div className="view">
            <div className="img-fluid card-img-height" style={{ backgroundImage: `url("${props.PrimaryImage.url}")`, backgroundPosition: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}> </div>
                {/* <img src={props.PrimaryImage.url} className="card-img-top" alt="project" style={{ minHeight: '290px' }} onClick={() => props.toggleProject(props.id, true)} /> */}
                <div className=" rgba-white-slight">
                    {
                        props.Sold ?
                            <>
                                <div className="text-left grey-text sold">Sold</div>
                        </>
                            : null
                    }
                </div>
                <div className=" rgba-white-slight">
                    {
                        openhomes && openhomes.length > 0 ?
                        (
                            <div className="text-left open-home-card pl-lg-4 pl-2">Open Home {openhomes[0]}</div>
                        ) : null
                    }
                </div>
            </div>
            <div className="card-body project-card-border">
                <div className="text-left">
                    <div className="h6-responsive ml-lg-4 mr-lg-4 ml-2 mr-2 mt-4 mb-3 text-uppercase font-Montserret" style={{ letterSpacing: '2px' }}>{props.Title}</div>
                    <hr className="grey accent-2 mb-2 d-inline-block mx-auto mt-0" style={{ width: "100%" }} />
                    <div className="pcc-body">
                        <div className="lc-address-text text-uppercase ml-lg-4 mr-lg-4 ml-2 mr-2 font-Montserret" style={{ letterSpacing: '2px' }}>{props.Address}</div>
                        <div className="lc-address-text text-uppercase ml-lg-4 mr-lg-4 ml-2 mr-2 font-Montserret" style={{ letterSpacing: '2px' }}>{suburb}<span className="grey-text">{city}</span></div>
                    </div>
                </div>
                <div style={{ height: "55px" }}>
                    {/* Ammenities Start */}
                    <div className="text-left ml-lg-2 mr-2 mb-3">
                        <MDBRow>
                            <MDBCol sm="12" className="pt-1 opacity-sm">
                                {props.Bedrooms > 0 ? (
                                    <span className="lc-ammenity">
                                        {props.Bedrooms} <MDBIcon className="lc-ammenity-icon mr-3" icon="bed" />
                                    </span>
                                ) : (
                                        ""
                                    )}
                                {props.Bathroom > 0 ? (
                                    <span className="lc-ammenity">
                                        {props.Bathroom} <MDBIcon className="lc-ammenity-icon" icon="bath" />
                                    </span>
                                ) : (
                                        ""
                                    )}
                                {props.Living > 0 ? (
                                    <span className="lc-ammenity">
                                        {props.Living} <MDBIcon className="lc-ammenity-icon" icon="couch" />
                                    </span>
                                ) : (
                                        ""
                                    )}
                                {props.Kitchen > 0 ? (
                                    <span className="lc-ammenity">
                                        {props.Kitchen} <MDBIcon className="lc-ammenity-icon" icon="utensils" />
                                    </span>
                                ) : (
                                        ""
                                    )}
                                {props.Office > 0 ? (
                                    <span className="lc-ammenity">
                                        {props.Office}  <MDBIcon className="lc-ammenity-icon" icon="desktop" />
                                    </span>
                                ) : (
                                        ""
                                    )}
                                {props.Garage > 0 ? (
                                    <span className="lc-ammenity">
                                        {props.Garage} <MDBIcon className="lc-ammenity-icon" icon="car" />
                                    </span>
                                ) : (
                                        ""
                                    )}
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <hr className="grey accent-2 mb-2 d-inline-block mx-auto mt-0" style={{ width: "100%", bottom: "0px" }} />
                </div>
                {/* <p className="card-text white-text project-card-body">{ReactHtmlParser(props.blurb)}</p> */}
                <div className="text-center my-4">
                    <div className="btn btn-outline-white btn-md waves-effect" onClick={() => props.toggleProject(props.id, true)}>Learn more</div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;