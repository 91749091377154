import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBNavLink } from "mdbreact";
import './style.css';
import NewsletterRegisterContainer from '../NewsletterRegister/NewsletterFormContainer'
import NewsletterRegisterForm from '../NewsletterRegister/NewsletterRegisterForm'

const homeUrl = '/';
const contactUsUrl = '/contact-us';

class FooterPage extends React.Component {

  authCtx = null;

  navigate = (url) => {
    // navigate to top of page
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }

  toggleAuthModal = (show, page) => {
    this.authCtx.toggleShow(show, page);
  }

  logout = () => {
    this.authCtx.logout();
  }

  render() {
    this.authCtx = this.context.auth;

    return (
      <>
        <div id="subscribe">
          <NewsletterRegisterContainer>
            <NewsletterRegisterForm />
          </NewsletterRegisterContainer>
        </div>
        <MDBFooter color="primary-color" className="font-small">
          <div style={{ backgroundColor: "grey", borderBottom: "8px solid white", borderTop: "8px solid white" }}>
            <MDBContainer className="text-center text-md-left pt-2 pb-2">
              <MDBRow className="py-4 d-flex align-items-center">
                <MDBCol md="6" lg="5" className="text-center text-md-left mb-4 mb-md-0">
                  <h6 className="mb-0 font-weight-md white-text">
                    Follow us on our social networks for the latest updates.
              </h6>
                </MDBCol>
                <MDBCol md="6" lg="7" className="text-center text-md-right">
                  <a className="ins-ic" href="https://www.instagram.com/silverstar_developments/?hl=en" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram white-text mr-4 mr-lg-4"> </i>
                  </a>
                  <a className="fb-ic ml-0" href="https://www.facebook.com/silverstardevelopmentsltd/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f white-text mr-4 mr-lg-4"> </i>
                  </a>
                  <a className="ins-ic" href="https://www.youtube.com/channel/UCpNRHrDL593o2gy4WeWMP7w" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-youtube white-text mr-4 mr-lg-4"> </i>
                  </a>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </div>
          <MDBContainer className="mt-5 mb-4 text-center text-md-left footer-padding-top-bottom">
            <MDBRow className="mt-3">
              <MDBCol md="7" className="mb-4 m-lr-auto">
                <h5 className="text-uppercase font-Julius pb-3">
                  <strong>Silverstar Developments</strong>
                </h5>
                <p className="text-justify grey-text pr-lg-5">Silverstar Developments have enjoyed a proud history of building quality homes for New Zealanders for over 25 years. Our commitment to excellence continues to this very day and this is why we have helped hundreds of New Zealanders into their own quality home.
            </p>
                <div className="text-center text-lg-left pt-5 mb-5 mb-lg-0">
                  <img src={require('../../assets/img/logos/navLogo-white.png')} alt="logo" className="footer-logo" />
                </div>
              </MDBCol>
              <MDBCol md="5" className="mb-4 m-lr-auto">
                <h5 className="text-uppercase font-weight-bold font-Julius pb-3">
                  <strong>Contact us</strong>
                </h5>
                <p>
                  <i className="fa fa-phone mr-3 grey-text" /> <a className="grey-text" href="tel:+64212833396">+64 21 283 3396</a>
                </p>
                <p>
                  <i className="fa fa-envelope mr-3 grey-text" /> <a className="grey-text" href="mailto:sales@silverstardevelopments.co.nz">sales@silverstardevelopments.co.nz</a>
                </p>
                <p className="grey-text">
                  <i className="fa fa-map-marker-alt mr-3" /> PO Box 5648, Papanui, Christchurch, New Zealand
            </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <div className="footer-copyright footer-padding font-text text-center">
            <MDBContainer fluid>
              <br />
              <div className="white-text text-uppercase">
                &copy; {new Date().getFullYear()} Copyright Silverstar Developments
            </div>
              <p className="text-uppercase mb-2 grey-text"><a href="http://xodesoftware.com/" rel="noopener noreferrer" alt="Designed by xodesoftware.com" target="_blank">Designed by Xode</a></p>
            </MDBContainer>
          </div>
        </MDBFooter>
      </>
    );
  }
}
export default FooterPage;