import React, { Component } from 'react'
import './style.css'
import { MDBIcon, MDBRow, MDBCol, MDBBtn } from 'mdbreact'
import { IS_LOADING, LOADED } from '../../utils/StrapiRequestor'
import { openHomeDateConverter } from '../../utils/constants'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import ReactHtmlParser from 'react-html-parser'
import { withRouter } from 'react-router-dom'
import { cRequest } from '../../utils/customRequest'
import ReactGA from 'react-ga'

class SelectedProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: props.id,
      data: null,
      loading: IS_LOADING,
      consultants: [],
      openhomes: [],
      error: false,
      top: 0,
      height: window.innerHeight,
      images: [],
      hasVideo: false,
      slideIndex: 0
    }
    this.applyScrollLock(true)
  }

  applyScrollLock = (locked = false) => {
    if (locked) {
      document.body.className = 'no-scroll'
      window.iNoBounce.enable()
    } else {
      document.body.className = ''
      window.iNoBounce.disable()
    }
  }

  componentDidMount() {
    this.checkPathParam()
    this.setTop(0)
    this.listenToOrientation(true)
    this.fetchProject()
    window.addEventListener('popstate', this.navigatedBack)
    ReactGA.initialize('UA-180713181-1')
    ReactGA.pageview(`/property-for-sale?listing=${this.state.id}`)
  }

  checkPathParam = () => {
    let params = window.location.search
    if (params && params.includes('listing')) {
      params = params.replace('?', '')
      let paramArr = params.split('&')
      let listingParam = paramArr.find((p) => p.includes('listing'))
      paramArr = listingParam.split('=')
      if (paramArr && paramArr.length === 2) {
        this.setState({
          id: paramArr[1]
        })
      }
    }
  }

  fetchProject = async () => {
    let res = await cRequest(`/projects/${this.state.id}`)
    if (res && res.status === 200) {
      let project = await res.json()
      if (project) {
        this.setState({
          data: project
        })
        this.setImageArray()
        this.checkOpenHomes(this.state.data)
        this.fetchConsultants(this.state.data)
        this.updateLoading(LOADED)
      }
    }
  }

  fetchConsultants = async (data) => {
    let consultants = data.consultants
    // if (!data.consultants || data.consultants.length === 0) {
    //   let response = await cRequest('/consultants')
    //   if (response && response.status === 200) {
    //     consultants = await response.json()
    //   }
    // }
    if (consultants) {
      let sortedConsultants = consultants.sort((a, b) => (a.pageOrder > b.pageOrder ? 1 : -1))
      this.setState({
        consultants: sortedConsultants
      })
    }
  }

  checkOpenHomes = (data) => {
    if (data && data.openhomes) {
      let ohTimes = openHomeDateConverter(data.openhomes)
      this.setState({
        openhomes: ohTimes
      })
    }
  }

  listenToOrientation(listen = false) {
    const setTop = this.setTop
    if (listen) {
      window.addEventListener('orientationchange', () => {
        setTop()
      })
    } else {
      window.removeEventListener('orientationchange', () => {
        setTop()
      })
    }
  }

  componentWillUnmount() {
    this.applyScrollLock()
    window.removeEventListener('popstate', this.navigatedBack)
  }

  navigatedBack = () => {
    this.close()
  }

  updateLoading = (loadState) => {
    setTimeout(() => {
      this.setState({
        loading: loadState
      })
    }, 800)
  }

  setImageArray = () => {
    let imageArr = []
    if (this.state.data) {
      let url = this.state.data.PrimaryImage.url
      let thumbUrl = this.getImageThumb(this.state.data.PrimaryImage)
      imageArr.push({ original: url, thumbnail: thumbUrl })
      for (let i = 0; i < this.state.data.AdditionalImages.length; i++) {
        url = this.state.data.AdditionalImages[i].url
        thumbUrl = this.getImageThumb(this.state.data.AdditionalImages[i])
        imageArr.push({ original: url, thumbnail: thumbUrl })
      }
    }

    let hasVideo = false
    if (this.state.data && this.state.data.video && this.state.data.video.url) {
      hasVideo = true
      let videoSlide = {
        thumbnail: require('../../assets/img/virtual-tour.jpeg'),
        embedUrl: this.state.data.video.url,
        description: 'Virtual Tour Video',
        renderItem: this.renderVideo.bind(this)
      }
      imageArr.splice(1, 0, videoSlide)
    }

    this.setState({
      hasVideo: hasVideo,
      images: imageArr
    })
  }

  toggleShowVideo(url) {
    this.state.showVideo[url] = !Boolean(this.state.showVideo[url])
    this.setState({
      showVideo: this.state.showVideo
    })

    if (this.state.showVideo[url]) {
      if (this.state.showPlayButton) {
        this.setState({ showGalleryPlayButton: false })
      }

      if (this.state.showFullscreenButton) {
        this.setState({ showGalleryFullscreenButton: false })
      }
    }
  }

  renderVideo(item) {
    return (
      <div>
        <div className='video-wrapper'>
          <a className='close-video' onClick={this.toggleShowVideo.bind(this, item.embedUrl)}></a>
          <iframe className='video-iframe' src={item.embedUrl} frameBorder='0' allowFullScreen></iframe>
        </div>
      </div>
    )
  }

  showFullscreen = () => {
    if (this.state.hasVideo) {
      return this.state.slideIndex !== 1
    } else {
      return true
    }
  }

  getImageThumb = (image) => {
    if (image.formats && image.formats.small && image.formats.small.url) {
      return image.formats.small.url
    } else {
      return image.url
    }
  }

  onSlide = (index) => {
    this.setState({
      slideIndex: index
    })
  }

  close = () => {
    this.props.toggleProject(this.state.data.id, false)
  }

  setTop = (timeout = 250) => {
    setTimeout(() => {
      const top = window.scrollY
      const height = window.innerHeight
      if (top !== this.state.top) {
        this.setState({
          top: top,
          height: height
        })
      }
    }, timeout)
  }

  renderLeftNav = (onClick, disabled) => {
    return (
      <div className='image-gallery-custom-left-nav' disabled={disabled} onClick={onClick}>
        <MDBIcon icon='chevron-left' className='image-gallery-custom-left-nav-icon' />
      </div>
    )
  }

  renderRightNav = (onClick, disabled) => {
    return (
      <div className='image-gallery-custom-right-nav' disabled={disabled} onClick={onClick}>
        <MDBIcon icon='chevron-right' className='image-gallery-custom-right-nav-icon' />
      </div>
    )
  }

  renderPlayPauseButton = (onClick, isPlaying) => {
    return (
      <div
        // type='button'
        className={'image-gallery-custom-play-button'}
        onClick={onClick}
      >
        {isPlaying ? (
          <MDBIcon icon='stop' className='image-gallery-custom-play-icon' />
        ) : (
          <MDBIcon icon='play' className='image-gallery-custom-play-icon' />
        )}
      </div>
    )
  }

  renderFullscreenButton = (onClick, isFullscreen) => {
    return (
      <div
        // type='button'
        className={'image-gallery-custom-fullscreen-button'}
        onClick={onClick}
      >
        {isFullscreen ? (
          <MDBIcon icon='compress' className='image-gallery-custom-fullscreen-icon' />
        ) : (
          <MDBIcon icon='expand' className='image-gallery-custom-fullscreen-icon' />
        )}
      </div>
    )
  }

  renderItem = (data) => {
    return <div className='image-gallery-custom-render-item' style={{ backgroundImage: `url(${data.original})` }}></div>
  }

  getAskingPrice = () => {
    if (
      !this.state.data ||
      !this.state.data.askingPrice ||
      this.state.data.askingPrice === 0 ||
      this.state.data.askingPrice.startsWith('0')
    )
      return null

    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(
      this.state.data.askingPrice
    )
  }

  render() {
    this.setTop()
    const suburb = this.state.data && this.state.data.suburb && this.state.data.suburb.Name ? this.state.data.suburb.Name : null
    const city = this.state.data && this.state.data.city && this.state.data.city.Name ? ' | ' + this.state.data.city.Name : null

    const consultants = this.state.consultants
      ? this.state.consultants.map((c, i) => {
          let image = c.photo && c.photo.length > 0 ? c.photo[0].url : ''
          return (
            <MDBCol md='4' key={i} className='mx-auto'>
              <img src={image} className='rounded text-center mx-auto size20' alt={`${c.firstname} ${c.lastname}`} />
              <div className='mt-2 text-uppercase h6-responsive' style={{ color: '#cda177', fontWeight: '600', letterSpacing: '1px' }}>
                {c.firstname} {c.lastname}
              </div>
              <p>
                <i className='fa fa-envelope mr-2 white-text' />{' '}
                <a
                  className='white-text lc-address-text mr-lg-4 mr-2'
                  style={{ letterSpacing: '2px' }}
                  href={
                    `mailto:${c.email}?subject=Listing Enquiry for ` +
                    this.state.data.Address +
                    '&body=Hi, I am interested in ' +
                    this.state.data.Address +
                    '.'
                  }
                >
                  {c.email}
                </a>
                <i className='fa fa-phone mr-2 white-text' />{' '}
                <a className='white-text lc-address-text' href={`tel:${c.mobilephone}`} style={{ letterSpacing: '2px' }}>
                  {c.mobilephone}
                </a>
              </p>
              <hr className='grey accent-2 mb-4 mt-2 d-inline-block mx-auto' style={{ width: '95%', height: '0.5px', opacity: '0.2' }} />
            </MDBCol>
          )
        })
      : null

    const schools =
      this.state.data && this.state.data.schools.length > 0 ? (
        <MDBCol md='12'>
          <div className='mt-1 lc-address-text text-uppercase font-Montserret white-text' style={{ letterSpacing: '2px' }}>
            School zones
          </div>
          <hr className='grey mt-1 mb-3 mt-0 d-inline-block mx-auto' style={{ width: '100%', bottom: '0px' }} />
          {this.state.data.schools.map((s, i) => (
            <MDBCol key={i} className='pl-lg-0'>
              <p className='white-text h6-responsive text-left' style={{ fontSize: '14px', letterSpacing: '1px' }}>
                {s.name}
              </p>
            </MDBCol>
          ))}
        </MDBCol>
      ) : null

    const openhomes =
      this.state.openhomes && this.state.openhomes.length > 0 ? (
        <>
          <div className='mt-1 lc-address-text text-uppercase font-Montserret white-text' style={{ letterSpacing: '2px' }}>
            Open Homes
          </div>
          {this.state.openhomes.map((oh, i) => (
            <div key={i} className='lc-address-text font-Montserret' style={{ letterSpacing: '2px' }}>
              {oh}
            </div>
          ))}
          <div className='display-none-sm'>
            <hr className='accent-2 mb-4 d-inline-block mx-auto' style={{ width: '100%', height: '0.5px', backgroundColor: 'grey' }} />
          </div>
        </>
      ) : null

    const completion =
      this.state.data && this.state.data.Completion ? (
        <div className='lc-address-text font-Montserret' style={{ letterSpacing: '2px' }}>
          Due for completion {this.state.data.Completion}
        </div>
      ) : null

    return (
      <div className='selected-listing-container' style={{ top: this.state.top }}>
        <div className='selected-listing-nav'>
          {this.props.nav}
          <hr className='grey accent-2 d-inline-block mx-auto mt-0' style={{ width: '100%', bottom: '-16px', position: 'absolute' }} />
        </div>

        <div className='selected-listing-inner-container'>
          <div className='selected-listing-inner-content'>
            {this.state.loading ? (
              <MDBRow style={{ height: '10px' }}>
                <MDBCol>
                  <div className='white-text loader-container'>
                    <div className='spinner-border' role='status'>
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </div>
                </MDBCol>
              </MDBRow>
            ) : (
              <>
                <MDBIcon icon='chevron-left' className='back-icon white-text ml-3' onClick={() => this.close()} />
                <MDBRow style={{ marginRight: '0px !important', marginLeft: '0px !important' }}>
                  <MDBCol size='11' className='pt-lg-2'>
                    <div
                      className='white-text h3-responsive mb-3 text-uppercase font-Montserret mt-lg-0 mt-3'
                      style={{ letterSpacing: '2px' }}
                    >
                      {this.state.data.Title}
                    </div>
                    <div className='lc-address-text-lg text-uppercase font-Montserret' style={{ letterSpacing: '2px' }}>
                      {ReactHtmlParser(this.state.data.Address)}
                    </div>
                    <div className='lc-address-text-lg text-uppercase font-Montserret' style={{ letterSpacing: '2px' }}>
                      {suburb}
                      <span className='grey-text'>{city}</span>
                    </div>
                  </MDBCol>
                  <MDBCol size='1'>
                    {/* <div> */}
                    {/* <img src={require('../../assets/img/logos/navLogo-white.png')} alt="logo" className="pointer" style={{ margin: '10px 15px', height: '65px' }} /> */}
                    {/* <div className="close-outer-container pl-lg-5 pl-0"> */}
                    <div className='close-container pl-lg-4' onClick={() => this.close()}>
                      <div className='leftright'></div>
                      <div className='rightleft'></div>
                      {/* <div className="close-x close-label">CLOSE</div> */}
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size='12'>
                    <ImageGallery
                      items={this.state.images}
                      showIndex={true}
                      useBrowserFullscreen={false}
                      renderItem={this.renderItem}
                      showPlayButton={this.showFullscreen()}
                      showFullscreenButton={true}
                      onBeforeSlide={this.onSlide}
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol size='12'>
                    <div className='mt-5 lc-address-text text-uppercase font-Montserret white-text pl-2' style={{ letterSpacing: '2px' }}>
                      Property details
                    </div>
                    <hr className='grey mt-1 mb-3 mt-0 d-inline-block mx-auto' style={{ width: '100%', bottom: '0px' }} />
                  </MDBCol>
                  <MDBCol md='6'>
                    <div className='text-left'>
                      <MDBRow className='opacity-sm'>
                        <MDBCol md='12' style={{ marginBottom: '15px' }}>
                          {this.state.data.Bedrooms > 0 ? (
                            <span className='lc-ammenity'>
                              {this.state.data.Bedrooms} <MDBIcon className='lc-ammenity-icon mr-3' icon='bed' />
                            </span>
                          ) : (
                            ''
                          )}
                          {this.state.data.Bathroom > 0 ? (
                            <span className='lc-ammenity'>
                              {this.state.data.Bathroom} <MDBIcon className='lc-ammenity-icon' icon='bath' />
                            </span>
                          ) : (
                            ''
                          )}
                          {this.state.data.Living > 0 ? (
                            <span className='lc-ammenity'>
                              {this.state.data.Living} <MDBIcon className='lc-ammenity-icon' icon='couch' />
                            </span>
                          ) : (
                            ''
                          )}
                          {this.state.data.Kitchen > 0 ? (
                            <span className='lc-ammenity'>
                              {this.state.data.Kitchen} <MDBIcon className='lc-ammenity-icon' icon='utensils' />
                            </span>
                          ) : (
                            ''
                          )}
                          {this.state.data.Office > 0 ? (
                            <span className='lc-ammenity'>
                              {this.state.data.Office} <MDBIcon className='lc-ammenity-icon' icon='desktop' />
                            </span>
                          ) : (
                            ''
                          )}
                          {this.state.data.Garage > 0 ? (
                            <span className='lc-ammenity'>
                              {this.state.data.Garage} <MDBIcon className='lc-ammenity-icon' icon='car' />
                            </span>
                          ) : (
                            ''
                          )}
                          {this.state.data.FloorSqm > 0 ? (
                            <h6
                              className='selected-listing-sub-title-sm mt-2 lc-address-text text-uppercase font-Montserret'
                              style={{ letterSpacing: '2px' }}
                            >
                              Floor Area &nbsp; {this.state.data.FloorSqm}&#13217; &nbsp;
                            </h6>
                          ) : null}

                          {this.state.data.LandSqm > 0 ? (
                            <h6
                              className='selected-listing-sub-title-sm lc-address-text text-uppercase font-Montserret'
                              style={{ letterSpacing: '2px' }}
                            >
                              Land Area &nbsp; {this.state.data.LandSqm}&#13217; &nbsp;
                            </h6>
                          ) : null}
                        </MDBCol>
                      </MDBRow>
                    </div>
                  </MDBCol>
                  <MDBCol md='6' className='text-lg-right text-left mb-3'>
                    {!this.state.data.Sold ? (
                      <>
                        {!this.getAskingPrice() ? (
                          <MDBCol sm='12'>
                            {this.state.data.sale_type ? (
                              <div className='lc-address-text font-Montserret' style={{ letterSpacing: '2px' }}>
                                {this.state.data.sale_type.Name}
                              </div>
                            ) : null}
                          </MDBCol>
                        ) : (
                          <MDBCol sm='12'>
                            <div className='lc-address-text font-Montserret' style={{ letterSpacing: '2px' }}>
                              Asking Price {this.getAskingPrice()}
                            </div>
                          </MDBCol>
                        )}
                      </>
                    ) : (
                      <div className='lc-address-text text-uppercase font-Montserret ml-3 ml-lg-0' style={{ letterSpacing: '2px' }}>
                        Sold
                      </div>
                    )}
                    <MDBCol sm='12'>{completion}</MDBCol>
                  </MDBCol>
                  {this.state.data.Bedrooms > 0 ? (
                    <MDBCol size='12'>
                      <hr className='grey mt-1 mb-3 d-inline-block mx-auto' style={{ width: '100%' }} />
                    </MDBCol>
                  ) : (
                    ''
                  )}
                </MDBRow>

                <MDBRow className='pr-lg-3 pl-2 pr-2'>
                  <MDBCol md='12'>
                    {openhomes}
                    <div
                      className='selected-listing-blurb h6-responsive font-Montserret text-justify pr-lg-5'
                      style={{ letterSpacing: '2px' }}
                    >
                      {ReactHtmlParser(this.state.data.Blurb)}
                    </div>
                    <div className='mb-5'>{schools}</div>
                  </MDBCol>
                </MDBRow>
                <div
                  className='mx-auto text-center mt-4 p-0'
                  style={{ border: '1px solid grey', borderRadius: '2px', maxWidth: '1200px', minWidth: '', height: '100%' }}
                >
                  <div className='h6-responsive font-Montserret white-text pb-4 pt-4' style={{ letterSpacing: '2px' }}>
                    Interested? Get in touch today.
                  </div>
                  <MDBRow>{consultants}</MDBRow>
                  {this.state.consultants.some((c) => {
                    const email = c.email.split('@')[1]

                    if (email === 'ere.nz') {
                      return true
                    }

                    return false
                  }) ? (
                    <>
                      <hr className='grey mt-1 mb-3 mt-0 d-inline-block mx-auto' style={{ width: '100%' }} />
                      <img
                        src={require('../../assets/img/logos/ere-logo.png')}
                        alt='ere logo'
                        style={{ maxWidth: '50%' }}
                        className='mb-4 mx-auto centred'
                      />
                    </>
                  ) : (
                    <>
                      <hr className='grey mt-1 mb-3 mt-0 d-inline-block mx-auto' style={{ width: '100%' }} />
                      <img
                        src={require('../../assets/img/logos/navLogo.png')}
                        alt='silverstar developments logo'
                        style={{ maxWidth: '25%' }}
                        className='mb-4 mx-auto centred'
                      />
                    </>
                  )}
                </div>
                <div className='text-center mt-4 mb-4'>
                  <MDBBtn outline color='white' style={{ minWidth: '310px' }} onClick={() => this.close()}>
                    View more homes for sale
                  </MDBBtn>
                  <MDBBtn
                    outline
                    color='white'
                    style={{ minWidth: '310px' }}
                    onClick={() => this.props.history.push('/neighbourhood-guide')}
                  >
                    See our neighbourhood guide
                  </MDBBtn>
                </div>
                <div className='listing-footer-container'>
                  <hr className='grey accent-2 mb-2 d-inline-block mx-auto mt-5' style={{ width: '100%' }} />
                  <div className='footer-copyright footer-padding font-text text-center'>
                    <br />
                    <div className='white-text text-uppercase'>&copy; {new Date().getFullYear()} Copyright Silverstar Developments</div>
                    <p className='text-uppercase mb-2 grey-text'>
                      <a href='http://xodesoftware.com/' rel='noopener noreferrer' alt='Designed by xodesoftware.com' target='_blank'>
                        <div className='grey-text'>Designed by Xode</div>
                      </a>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SelectedProject)
