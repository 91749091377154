import React, { useEffect, useState, useContext } from 'react';
import { RequestorContext } from '../../utils/StrapiRequestor';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact';
// import ProjectHeader from '../HeaderComponents/ProjectsSoldHeader'
import ScrollToTop from '../Nav/ScrollToTop';
import { Helmet } from 'react-helmet';
import VideoCardPageContainer from '../CardComponents/VideosPageContainer';
import VideoCardContainer from '../CardComponents/VideoCardContainer';
import './style.css'
import ReactGA from 'react-ga';


function SoldProjectsPage() {

    useEffect(() => {
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/virtual-tours');
    }, []);


    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Virtual Tours | Silverstar Developments</title>
                <link rel="canonical" href="https://www.silverstardevelopments.co.nz/virtual-tours" />
            </Helmet>
            <ScrollToTop />
            <div style={{ backgroundColor: "white" }}>
                <div className="virtual-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter mb-2">
                    <div className="header-text">
                        <div className="h1-responsive text-left center secondary-text font-weight-md mb-4 pl-lg-2 pl-2">Virtual Tours</div>
                        <h1 className="h1-responsive text-left center white-text font-weight-md mb-4 pl-lg-2 pl-2">Take a look around our homes, virtually.</h1>
                    </div>
                </div>

                <div id="videos" className="secondary-bg pt-5 pb-5">
                    <div className="mb-4 pr-lg-5 pl-lg-5 pr-2 pl-2 text-center pt-3">
                        <div className="pb-5 text-center">
                            <VideoCardPageContainer />
                        </div>
                        {/* <div className="h1-responsive font-Yellowtail mt-3 mb-4 text-lg-right text-center">Like what you see? Get in touch.</div> */}
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SoldProjectsPage;