import React, { useEffect, useState, useContext } from 'react';
import { RequestorContext } from '../../utils/StrapiRequestor';
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
// import ProjectHeader from '../HeaderComponents/ProjectsSoldHeader'
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import LoadMoreButton from '../ButtonComponents/LoadMoreButton';
import SelectedProject from '../SelectedProject/SelectedProject';
import ProjectCard from '../CardComponents/ProjectCard';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';


function SoldProjectsPage(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(9);
    const [isMore, setIsMore] = useState(true);
    const [projectId, setProjectId] = useState(null);
    const [showproject, setShowProject] = useState(null);
    const ctx = useContext(RequestorContext);

    useEffect(() => {
        getProjects();
        checkPathParam();
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/sold-property');
    }, []);

    const checkPathParam = () => {
        let params = window.location.search;
        if (params && params.includes('listing')) {
            params = params.replace('?', '');
            let paramArr = params.split('&');
            let listingParam = paramArr.find(p => p.includes('listing'));
            paramArr = listingParam.split('=');
            if (paramArr && paramArr.length === 2) {
                toggleProject(paramArr[1], true);
            } else {
                toggleProject(0, false);
            }
        }
    }

    const toggleProject = (id, show = false) => {
        if (show) {
            props.requestor.setToggleCloseFunc(toggleProject);
            history.push(`?listing=${id}`);
        } else {
            history.push(window.location.pathname.split("?")[0])
        }
        setProjectId(id);
        setShowProject(show);
    }

    const getProjects = () => {
        setLoading(true);
        setTimeout(() => {
            ctx.requestor.projectsSold(skip, take)
                .then(res => res.json())
                .then(data => {
                    let cProjects = projects.concat(data);
                    setProjects(cProjects);
                    if (data.length < take) {
                        setIsMore(false);
                    }
                    let nSkip = skip + take;
                    setSkip(nSkip);
                }).catch(error => { })
                .finally(() => {
                    setLoading(false)
                });
        }, 500);
    }

    const projectPage = showproject ? (<SelectedProject id={projectId} toggleProject={toggleProject} requestor={props.requestor} nav={props.nav} />) : null;
    const cards = projects.map(p => <MDBCol md="4" key={p.id}><ProjectCard {...p} toggleProject={toggleProject} /></MDBCol>);

    return (
        <div>
            {projectPage}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sold Property | Silverstar Developments</title>
                {/* <description>Contact us to find out more.</description> */}
                <link rel="canonical" href="https://www.silverstardevelopments.co.nz/sold-property" />
            </Helmet>
            <ScrollToTop />
            <div style={{ backgroundColor: "white" }}>
                <div className="sold-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter mb-2">
                    <div className="header-text">
                        <div className="h1-responsive text-left center secondary-text font-weight-md mb-4 pl-lg-2">Sold developments</div>
                        <h1 className="h1-responsive text-left center white-text font-weight-md mb-4 pl-lg-2">Our recently sold property</h1>
                    </div>
                </div>
                <div className="pt-5 pb-5 default-bg">
                    <div className="mb-4 pt-5 pr-lg-5 pl-lg-5 pr-2 pl-2">
                        <div className="text-center">
                            <MDBRow>
                                {cards}
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <LoadMoreButton text={isMore ? "View More" : "End"} loading={loading} cb={getProjects} disabled={!isMore} />
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SoldProjectsPage;