import React, { useEffect } from 'react';
import './App.css';
import StrapiRequestor from './utils/StrapiRequestor';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-180713181-1');

export function scrollTo(id) {
  var testDiv = document.getElementById(id);
  window.scrollTo({
    top: (testDiv.offsetTop - 50),
    behavior: 'smooth',
  });
}

function App() {

    // if we reach this far we are expecting to display content
  // find loader element and hide
  const ele = document.getElementById('init-loader');
  if (ele) {
    window.setTimeout(() => {
      ele.classList.add('loading-container-fade');
      window.setTimeout(() => {
        ele.classList.add('hide-loader');
      }, 550);
    }, 2600);
  }

  return (
    <StrapiRequestor />
  );
}

export default App;
