import React, { Component } from "react";
import AboutUsPage from '../Components/About/AboutPage';
import PropertyTeam from '../Components/About/PropertyTeam'
import HomePage from '../Components/Home/HomePage';
import Contact from '../Components/Contact/ContactPage'
import Footer from '../Components/Footer/Footer'
import Nav from '../Components/Nav/Nav';
import SoldProjectsPage from '../Components/SelectedProject/SoldProjectsPage';
import LatestProjectsPage from '../Components/SelectedProject/LatestProjectsPage';
import VirtualTours from '../Components/Videos/VideoPage'
import Explore from '../Components/About/Neighbourhood'
import ContactPage from '../Components/Contact/ContactPage'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

export const IS_LOADING = true;
export const LOADED = false;
export const RequestorContext = React.createContext();

export const STRAPI_URL = 'https://admin.silverstardevelopments.co.nz';
// export const STRAPI_URL = 'http://localhost:8082';

export default class StrapiRequestor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            testData: 'Hi, im test data :)'
        };
    }

    setToggleCloseFunc = (func) => {
        this.toggleClose = func;
    }

    toggleCloseFunc = () => {
        if (this.toggleClose) {
            this.toggleClose(0, false);
        }
    }

    projectsLatest(start, limit) {
        let url = STRAPI_URL + '/projects';
        let query = '?_start=' + start + '&_limit=' + limit + '&Sold=false&_sort=Go_Live_Date:desc';
        return fetch(url + query);
    }

    projectsSold(start, limit) {
        let url = STRAPI_URL + '/projects';
        let query = '?_start=' + start + '&_limit=' + limit + '&Sold=true&_sort=Go_Live_Date:desc';
        return fetch(url + query);
    }

    project(id) {
        let url = STRAPI_URL + '/projects';
        return fetch(url + '/' + id);
    }

    projectsCount() {
        let url = STRAPI_URL + '/projects/count';
        return fetch(url);
    }

    videosLatest(start, limit) {
        let url = STRAPI_URL + '/videos';
        let query = '?_start=' + start + '&_limit=' + limit + '&_sort=created_at:desc';
        return fetch(url + query);
    }


    render() {
        const nav = (<Nav requestor={this} />);
        return (
            <RequestorContext.Provider value={{ requestor: this }}>
                <div style={{ width: '100%', overflow: 'hidden' }}>
                    <Router>
                        {nav}
                        <Switch>
                            <Route exact path="/property-developers">
                                <AboutUsPage />
                            </Route>
                            <Route exact path="/neighbourhood-guide">
                                <Explore />
                            </Route>
                            <Route exact path="/property-team">
                                <PropertyTeam />
                            </Route>
                            <Route exact path="/contact-us">
                                <ContactPage />
                            </Route>
                            <Route exact path="/property-for-sale">
                                <LatestProjectsPage requestor={this} nav={nav} />
                            </Route>
                            <Route exact path="/sold-property">
                                <SoldProjectsPage requestor={this} nav={nav} />
                            </Route>
                            <Route exact path="/virtual-tours">
                                <VirtualTours />
                            </Route>
                            <Route exact path="/contact-us">
                                <Contact />
                            </Route>
                            <Route path="/">
                                <HomePage requestor={this} nav={nav} />
                            </Route>
                        </Switch>
                        <Footer />
                    </Router>
                </div>
            </RequestorContext.Provider>
        );
    }
}