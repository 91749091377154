import React from 'react';

const NewsletterRegisterContainer = ({ children }) => {
    return (
        <div className="pt-3 pb-1" style={{ borderTop: '8px solid white', backgroundColor: 'rgb(75, 75, 75)', color: 'white' }}>
            {children}
        </div>
    )
}

export default NewsletterRegisterContainer;