import React from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation, MDBIcon, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact'
import { scrollTo } from '../../App';
import ScrollToTop from '../Nav/ScrollToTop'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom';
import './style.css'
import { Parallax } from 'react-parallax'
import ReactGA from 'react-ga';


class AboutPage extends React.Component {

    componentDidMount() {
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/property-developers');
    }


    render() {
        return (
            <div className="mx-auto" style={{ overflow: "hidden", backgroundColor: 'white', color: 'black' }}>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Property Developers | Silverstar Developments</title>
                    {/* <description>Contact us to find out more.</description> */}
                    <link rel="canonical" href="https://www.silverstardevelopments.co.nz/property-developers" />
                </Helmet>
                <ScrollToTop />
                <div style={{ backgroundColor: "white" }}>
                    <div className="about-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter mb-2">
                        <div className="header-text">
                            <div className="h1-responsive text-left center secondary-text font-weight-md mb-4 pl-lg-2 pl-2">About us</div>
                            <h1 className="h1-responsive text-left center white-text font-weight-md mb-4 pl-lg-2 pl-2">Executive property developer</h1>
                        </div>
                    </div>
                    <MDBRow className="mb-2">
                        <MDBCol md="12" lg="6" className="secondary-bg">
                            <div className="center-container-outer pb-5 pt-5">
                                <div className="center-container-inner">
                                    <MDBAnimation type="slideInLeft" className="fast">
                                        <MDBCol md="10" className="mx-auto pt-5 pb-5">
                                            <h6 className="h1-responsive font-Yellowtail mt-3 mb-4 text-left">Executive property developer</h6>
                                            <div className="mb-4 h5-repsonsive justify-text">
                                                There are plenty of reasons why you should build your brand new home with Silverstar Developments. We have enjoyed a proud history of building quality homes for New Zealanders for over 25 years. Our commitment to excellence continues to this very day and this is why we have helped hundreds of New Zealanders into their very own home.
                                                <br /><br />
                                                We want to sell you a home for the right reasons. Because the home suits your needs perfectly, without compromise, without breaking your budget. Because you simply fall in love with the design, and what it will mean for your lifestyle and those you love.
                                            </div>
                                        </MDBCol>
                                    </MDBAnimation>
                                </div>
                            </div>
                        </MDBCol>
                        <MDBCol md="12" lg="6" className="p-0" style={{ minHeight: "350px" }}>
                            <div className="background-home-1"></div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mb-2">
                        <MDBCol md="12" lg="6" className="p-0" style={{ minHeight: "350px" }}>
                            <div className="background-home-2"></div>
                        </MDBCol>
                        <MDBCol md="12" lg="6" className="secondary-bg">
                            <div className="center-container-outer pb-5 pt-5">
                                <div className="center-container-inner">
                                    <MDBAnimation reveal type="slideInRight" className="fast">
                                        <MDBCol md="10" className="mx-auto pt-5 pb-5">
                                            <h6 className="h1-responsive font-Yellowtail mt-3 mb-4 text-lg-right text-left">Stylish residential homes</h6>
                                            <div className="mb-4 h5-repsonsive justify-text">
                                                Silverstar Developments have been creating and constructing stylish residential homes in Canterbury for over 20 years and have built well over 100 homes.
                                                We are committed to providing superior service and stand behind our quality 100%.
                                            <br /><br />Currently focusing on the St Albans and Merivale areas in Christchurch, we have had the privilege to work on some very high-profile and prestigious projects across the region – of which we are justifiably proud.
                                        </div>
                                        </MDBCol>
                                    </MDBAnimation>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>

                    <div id="interior" className="pt-5 pb-5">
                        <MDBContainer className="mt-5 mb-5">
                            <MDBRow>
                                <MDBCol md="4" className="mb-lg-0 mb-5 text-center">
                                    <img src={require('../../assets/img/interior.png')} alt="interior" className="w-100 img-fluid" />
                                </MDBCol>
                                <MDBCol md="8" className="text-left">
                                    <MDBCol md="8" className="mx-auto">
                                        <div style={{ lineHeight: "29px" }}>
                                            <h2 className="h2-responsive">What sets us apart</h2>
                                            <div className="grey-text mt-4 mb-4">
                                                <MDBIcon icon="angle-right" className="mr-3" /> No two designs are the same. <br />
                                                <MDBIcon icon="angle-right" className="mr-3" /> We have our own in-house designer. <br />
                                                <MDBIcon icon="angle-right" className="mr-3" /> We offer a complete package for your new home.
                                        </div>
                                            <MDBBtn outline color="secondary" onClick={() => this.props.history.push('/property-team')}>Meet the team</MDBBtn>
                                        </div>
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>

                    <div className="default-bg pt-5 pb-5" id="more">
                        <MDBContainer className="text-left pt-5 pb-5">
                            <MDBCol md="12" className="mx-auto">
                                <h2 className="h1-responsive mt-3 mb-5 mt-5 white-text text-center">We maintain the highest standards in the construction industry</h2>
                                <MDBRow>
                                    <MDBCol md="6" className="mx-auto">
                                        <div className="white-text h6-responsive font-quatBold mb-5 text-justify">
                                            Whatever your preference, rest assured, you will not be disappointed with the quality of service which Silverstar Developments delivers. Our construction projects are managed by Licensed Building Practitioners who, along with our Managing Director Tony Anderson, have applied their many years of experience in the construction industry to develop Silverstar Developments into the thriving company it is today.
                                    </div>
                                    </MDBCol>
                                    <MDBCol md="6" className="mx-auto">
                                        <div className="white-text h6-responsive font-quatBold mb-5 text-justify">
                                            Our team maintain the highest standards in the construction industry and implement leading edge construction methods. Guided by our Managing Director, Tony Anderson, we have built long-term relationships with the most skilful construction consultants, contractors, suppliers and personnel in the Central Canterbury region.
                                    </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBContainer>
                    </div>

                    <Parallax
                        bgImage={require('../../assets/img/ss-6.jpg')}
                        bgImageAlt="silverstar developments"
                        strength={200}
                        className="page-header header-filter clear-filter dark-filter white-text mt-2 mb-2"
                    >
                        <MDBContainer className="text-left" id="more">
                            <div style={{ zIndex: '2', position: "relative" }}>
                                <h2 className="h2-responsive">We have an established history in Canterbury.</h2>
                                <h6 className="h5-responsive mt-3 mb-lg-4">Silverstar Developments has been building quality homes all over the Canterbury region for over 20 years.</h6>
                                <MDBBtn color="white" outline onClick={() => this.props.history.push('/contact-us')}>Contact Us</MDBBtn>
                            </div>
                        </MDBContainer>

                        <div style={{ minHeight: '400px' }} />
                    </Parallax>

                </div>
            </div >
        );
    }
}

export default withRouter(AboutPage);