import React, { useContext, useEffect, useState } from 'react'
import { RequestorContext } from '../../utils/StrapiRequestor';
import { MDBRow, MDBCol } from 'mdbreact'
import ProjectCard from './ProjectCard'
// import LoadMoreButton from '../ButtonComponents/LoadMoreButton';

function ProjectCardMainPage(props) {

    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [skip, setSkip] = useState(0);
    const [take] = useState(3);
    const [isMore, setIsMore] = useState(true);

    const ctx = useContext(RequestorContext);

    const getProjects = () => {
        setLoading(true);
        setTimeout(() => {
            ctx.requestor.projectsSold(skip, take)
                .then(res => res.json())
                .then(data => {
                    let cProjects = projects.concat(data);
                    setProjects(cProjects);
                    if (data.length < take) {
                        setIsMore(false);
                    }
                    let nSkip = skip + take;
                    setSkip(nSkip);
                }).catch(error => { })
                .finally(() => {
                    setLoading(false)
                });
        }, 500);
    }

    useEffect(() => {
        getProjects();
    }, []);

    const cards = projects.map(p => <MDBCol md="6" lg="4" key={p.id}><ProjectCard {...p} toggleProject={props.toggleProject} /></MDBCol>);

    return (
        <>
            <MDBRow style={{ justifyContent: 'center' }}>
                {cards}
            </MDBRow>
        </>
    );
};

export default ProjectCardMainPage;