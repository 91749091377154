import React from 'react';
import { MDBAnimation, MDBCol, MDBContainer, MDBIcon, MDBRow, MDBBtn, MDBView, MDBMask } from "mdbreact";
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';


class NeighbourhoodPage extends React.Component {

    componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/neighbourhood-guide');
    }

    scrollToGetStarted() {
        var testDiv = document.getElementById("get-started");
        window.scroll({
            top: (testDiv.offsetTop - 25),
            behavior: 'smooth',
        });
    }

    render() {

        return (
            <>
                <Helmet>
                    <title>Neighbourhood Guide | Silverstar Developments</title>
                    <meta name="description"
                        content="A neighbourhood guide to the best established suburbs Christchurch has to offer." />
                    <meta name="author" content="Neighbourhood Guide | Silverstar Developments" />
                </Helmet>
                <div style={{ backgroundColor: "white" }}>
                    <div className="neighbourhood-header d-flex justify-content-center align-items-center page-header header-filter clear-filter dark-filter">
                        <div className="header-text">
                            <div className="h1-responsive text-left center secondary-text font-weight-md mb-4 pl-lg-2 pl-2">Discover the area</div>
                            <h1 className="h1-responsive text-left center white-text font-weight-md mb-4 pl-lg-2 pl-2">Your neighbourhood guide</h1>
                        </div>
                    </div>

                    <div className="background-darker" id="get-started">
                        <div className="">
                            <MDBRow className="no-padding-margin">
                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container">
                                    <img
                                        src={require("../../assets/img/park.png")}
                                        className="img-fluid img-explore"
                                        alt=""
                                    />
                                    <div className="centered text-uppercase"><h1><b className="text-gold heading-h3-lg heading-h3-sm"> Discover</b></h1>
                                        <h2 className="text-lg-right text-left text-uppercase grey-text">what's in the Area</h2>
                                    </div>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/food.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Restaurants</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="http://chillingworthroad.co.nz/" alt="Chillingworth Road Link" rel="noopener noreferrer">Chillingworth Road</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.tripadvisor.co.nz/Restaurant_Review-g255118-d2427072-Reviews-Leinster_Rd_Bistro-Christchurch_Canterbury_Region_South_Island.html" rel="noopener noreferrer" target="_blank" alt="Leinster Rd Bistro Link">Leinster Rd Bistro</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.tripadvisor.co.nz/Restaurant_Review-g255118-d12653385-Reviews-Inati-Christchurch_Canterbury_Region_South_Island.html" rel="noopener noreferrer" target="_blank" alt="Inati">Inati</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.amazonita.co.nz/" target="_blank" rel="noopener noreferrer" alt="Amazonita Link">Amazonita</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://riverside.nz/" target="_blank" rel="noopener noreferrer" alt="Riverside Market Link">Riverside Market</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/school.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Schools</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://www.cbhs.school.nz/" target="_blank" rel="noopener noreferrer" alt="Christchurch Boys High Link">Christchurch Boys' High</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://cghs.school.nz/" target="_blank" rel="noopener noreferrer" alt="Christchurch Girls High Link">Christchurch Girls' High</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://selwynhouse.school.nz/" target="_blank" rel="noopener noreferrer" alt="Selwyn House Link">Selwyn House</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://www.rangiruru.school.nz/" target="_blank" rel="noopener noreferrer" alt="Rangi Ruru Link">Rangi Ruru Girls'</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://www.stac.school.nz/" target="_blank" rel="noopener noreferrer" alt="STAC Link">St Andrews' College</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://christscollege.com/" target="_blank" rel="noopener noreferrer" alt="Christ College Link">Christ's College</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg  white-text mb-4"><a className="white-text" href="https://www.stmargarets.school.nz/" target="_blank" rel="noopener noreferrer" alt="Saint Margaret's College Link">St Margaret's College</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/shopping.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Shopping</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://merivalemall.co.nz/" target="_blank" rel="noopener noreferrer" alt="Merivale Mall Link">Merivale Mall</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://findchch.com/places/1573-fendalton-village-shopping-centre" target="_blank" rel="noopener noreferrer" alt="Fendalton Village Link">Fendalton Village Shopping Centre</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.westfield.co.nz/riccarton" target="_blank" rel="noopener noreferrer" alt="Westfield Riccarton Link">Westfield Riccarton</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://thecrossing.co.nz/" target="_blank" rel="noopener noreferrer" alt="The Crossing Link">The Crossing</a></h3>
                                            </div>                                    </MDBMask>
                                    </MDBView>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/drinks.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Caf&eacute;s & Bars</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://no4bar.co.nz/" target="_blank" rel="noopener noreferrer" alt="No 4 Bar Link">No. 4 Bar and Restaurant</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.meshino.co.nz/" target="_blank" rel="noopener noreferrer" alt="Meshino Link">Meshino</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.aikmans.nz/" target="_blank" rel="noopener noreferrer" alt="Aikmans Link">Aikmans</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.facebook.com/Room205chch/" rel="noopener noreferrer" target="_blank" alt="Room 205 Coffee House Link">Room 205 Coffee House</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="http://www.thepreservatory.co.nz/" rel="noopener noreferrer" target="_blank" alt="The Preservatory Link">The Preservatory</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>

                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container">
                                    <MDBView hover zoom>
                                        <img
                                            src={require("../../assets/img/park-kids.png")}
                                            className="img-fluid img-about"
                                            alt=""
                                        />
                                        <h1 className="centered">Parks</h1>
                                        <MDBMask className="flex-center black-color" >
                                            <div>
                                                {/* <h3 className="smaller-font-sm smaller-font-lg white-text mt-4 mb-4"><a className="white-text" href="https://ccc.govt.nz/parks-and-gardens/christchurch-botanic-gardens/visit" target="_blank" alt="Christchurch Botanic Gardens Link">Botanic Gardens</a></h3> */}
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://ccc.govt.nz/parks-and-gardens/explore-parks/hagley-park-and-gardens" target="_blank" rel="noopener noreferrer" alt="Christchurch Hagley Park Link">Hagley Park</a></h3>
                                                <h3 className="smaller-font-sm smaller-font-lg white-text mb-4"><a className="white-text" href="https://www.christchurchnz.info/business/margaret-mahy-playground" target="_blank" rel="noopener noreferrer" alt="Margaret Mahy Playground Link">Margaret Mahy Playground</a></h3>
                                            </div>
                                        </MDBMask>
                                    </MDBView>
                                </MDBCol>


                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container" style={{ borderBottom: 'none !important' }}>
                                    <img
                                        src={require("../../assets/img/coffee.png")}
                                        className="img-fluid img-explore"
                                        alt=""
                                    />
                                    <div className="centered text-uppercase"><h2><b className="text-right">A vibrant</b></h2>
                                        <h1 className="text-left text-uppercase grey-text heading-h3-lg heading-h3-sm">lifestyle</h1>
                                    </div>
                                </MDBCol>


                                <MDBCol sm="12" md="6" lg="6" className="no-padding-margin container" style={{ backgroundColor: 'black', minHeight: '400px', borderBottom: 'none !important' }}>
                                    <div className="centered">
                                        <div className="text-lg-right text-center">
                                            <MDBBtn outline color="white" size="lg" onClick={() => this.props.history.push('/property-for-sale')}>View homes for sale</MDBBtn>
                                        </div>
                                    </div>
                                </MDBCol>

                            </MDBRow>
                        </div>

                    </div>
                </div>
            </>
        )
    }
};

export default withRouter(NeighbourhoodPage);