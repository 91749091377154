import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import ProjectCardContainer from '../CardComponents/ProjectCardContainer';
import ProjectCardContainerSold from '../CardComponents/ProjectCardContainerSold';
import ScrollToTop from '../Nav/ScrollToTop'
import { scrollTo } from '../../App';
import { Helmet } from 'react-helmet'
import { Parallax } from 'react-parallax'
import SelectedProject from '../SelectedProject/SelectedProject';
import {
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBAnimation
} from "mdbreact";
import YouTube from 'react-youtube';
import "./style.css";
import {
    BrowserView,
    MobileView,
    isMobile
} from "react-device-detect";
import VideoCardContainer from "../CardComponents/VideoCardContainer";
import ReactGA from 'react-ga';

// const videoURL = 'https://www.youtube.com/watch?v=jBqb_8NmrGc&t=9s'

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
            projectId: null,
            showproject: null
        }
    };

    handleTogglerClick = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    };

    componentDidMount() {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
        this.checkPathParam();
        ReactGA.initialize('UA-180713181-1');
        ReactGA.pageview('/');
    }

    checkPathParam = () => {
        let params = window.location.search;
        if (params && params.includes('listing')) {
            params = params.replace('?', '');
            let paramArr = params.split('&');
            let listingParam = paramArr.find(p => p.includes('listing'));
            paramArr = listingParam.split('=');
            if (paramArr && paramArr.length === 2) {
                this.toggleProject(paramArr[1], true);
            } else {
                this.toggleProject(0, false);
            }
        }
    }

    _onReady(event) {
        const ele = document.getElementById('v-overlay');
        if (ele) {
            window.setTimeout(() => {
                ele.classList.add('video-overlay-fade');
            }, 1000)
        }
        event.target.playVideo();
    }

    _onEnd(event) {
        event.target.playVideo();
    }

    toggleProject = (id, show = false) => {
        if (show) {
            this.props.requestor.setToggleCloseFunc(this.toggleProject);
            this.history.push(`?listing=${id}`);
        } else {
            this.history.push(window.location.pathname.split("?")[0])
        }
        let state = { ...this.state };
        state.projectId = id;
        state.showproject = show;
        this.setState(state);
    }

    projectPage = () => {
        if (this.state.showproject)
            return (<SelectedProject id={this.state.projectId} toggleProject={this.toggleProject} requestor={this.props.requestor} nav={this.props.nav} />)
        else
            return null;
    }


    render() {
        this.history = this.props.history;

        const overlay = (
            <div
                id="sidenav-overlay"
                style={{ backgroundColor: "transparent" }}
                onClick={this.handleTogglerClick}
            />
        );

        const videoOptions = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                autoplay: 1,
                controls: 0,
                rel: 0,
                showinfo: 0,
                mute: 1
            }
        };

        return (
            <>
                {this.projectPage()}
                <div id="apppage" style={{ overflow: "hidden" }}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Silverstar Developments | Building Stylish Homes</title>
                        {/* <description>Description.</description> */}
                        <link rel="canonical" href="https://www.silverstardevelopments.co.nz" />
                    </Helmet>
                    <ScrollToTop />

                    {/* <div id="v-overlay" className="video-overlay">
                        <div className="video-overlay-inner"></div>
                    </div> */}

                    {
                        (isMobile) ?
                            <>
                                <Parallax
                                    bgImage={require('../../assets/img/ss2.jpg')}
                                    bgImageAlt="silverstar developments"
                                    // strength={300}
                                    className="page-header header-filter clear-filter dark-filter white-text"
                                >
                                    <div style={{ zIndex: '2', position: "relative" }}>
                                        <div className="mt-5 pb-5 text-left white-text center-container-outer" style={{ width: '100%', minHeight: "100vh" }}>
                                            <div className="center-container-inner" style={{ margin: "8%" }}>
                                                <MDBCol md="12" className="mx-auto pt-lg-5 mt-5">
                                                    <MDBAnimation type="fadeInDown" className="slow" delay='2s'>
                                                        <h6 className="h1-responsive font-Yellowtail mt-5 mb-4">Building stylish homes.</h6>
                                                    </MDBAnimation>
                                                    <h1 className="h1-responsive mb-4">We are the <b style={{ color: 'grey', backgroundColor: 'white', borderRadius: '3px', padding: '3px' }}>leading</b> edge in executive property development</h1>
                                                    <MDBBtn color="white" outline onClick={() => this.props.history.push('/property-for-sale')} >New Homes for Sale</MDBBtn>
                                                    <MDBBtn outline color="white" onClick={() => scrollTo('subscribe')} style={{ minWidth: '230px' }}>Subscribe</MDBBtn>
                                                </MDBCol>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ minHeight: '100vh' }} />
                                </Parallax>
                            </>
                            :
                            <>
                                <div className="video-background">
                                    <div className="video-foreground">
                                        <YouTube
                                            videoId="jBqb_8NmrGc"
                                            opts={videoOptions}
                                            className="video-iframe"
                                            onReady={this._onReady}
                                            onEnd={this._onEnd}
                                        />
                                    </div>
                                </div>
                                {/* </div> */}
                                <div className="pt-5 pb-5 text-left white-text center-container-outer" style={{ width: '100%', minHeight: "60vh" }}>
                                    <div className="center-container-inner pt-5" style={{ margin: "8%" }}>
                                        <MDBCol md="12" className="mx-auto pt-lg-5 pt-0">
                                            <MDBAnimation type="fadeInDown" className="slow" delay='2s'>
                                                <h6 className="h1-responsive font-Yellowtail mt-3 mb-4">Building stylish homes.</h6>
                                            </MDBAnimation>
                                            <h1 className="h1-responsive mb-4">We are the <b style={{ color: 'grey' }}>leading</b> edge in <br />executive property development</h1>
                                            <MDBBtn color="white" outline onClick={() => this.props.history.push('/property-for-sale')} style={{ minWidth: '273px' }}>New Homes for Sale</MDBBtn>
                                            <MDBBtn outline color="white" onClick={() => scrollTo('subscribe')} style={{ minWidth: '213px' }}>Subscribe to our newsletter</MDBBtn>
                                        </MDBCol>
                                    </div>
                                </div>
                            </>
                    }


                    <div style={{ backgroundColor: "white" }} className="" id="get-started">
                        <MDBRow className="pb-1 pt-1 pl-lg-1 pr-lg-1">
                            <MDBCol md="12" lg="6" className="secondary-bg no-padding-margin">
                                <div className="center-container-outer pb-5 pt-5">
                                    <div className="center-container-inner">
                                        <MDBAnimation type="slideInLeft" className="fast">
                                            <MDBCol md="10" className="mx-auto pt-5 pb-5">
                                                {/* <h6 className="h1-responsive font-Yellowtail mt-3 mb-4 text-left">Executive property developer</h6> */}
                                                <h2 className="h2-responsive mb-4 text-left">Silverstar Developments</h2>
                                                <div className="mb-4 h5-repsonsive justify-text">
                                                    We have enjoyed a proud history of building quality homes for New Zealanders for over 25 years. Our commitment to excellence continues to this very day and this is why we have helped hundreds of New Zealanders into their very own home.
                                                <br /><br />
                                                We want to sell you a home for the right reasons. Because the home suits your needs perfectly, without compromise, without breaking your budget. Because you simply fall in love with the design, and what it will mean for your lifestyle and those you love.
                                            </div>
                                                <MDBBtn className="text-center" color="white" outline onClick={() => this.props.history.push('/property-for-sale?listing=7')}><span style={{ fontWeight: '900' }}>Just listed</span> 17a Strowan Road <i className="fas fa-angle-right ml-2"></i></MDBBtn>
                                                {/* <div className="text-btn text-left" onClick={() => this.props.history.push('/property-for-sale?listing=7')}>View just listed 17a Strowan Road <i className="fas fa-angle-right pt-2 ml-2"></i></div> */}
                                            </MDBCol>
                                        </MDBAnimation>
                                    </div>
                                </div>
                            </MDBCol>
                            <MDBCol md="12" lg="6" className="p-0 no-padding-margin" style={{ minHeight: "350px" }}>
                                <div className="background-home"><div className="featured-address">17A STROWAN ROAD</div></div>
                            </MDBCol>
                        </MDBRow>

                        <div id="current-property" className="default-bg pt-5 pb-5">
                            <div className="mb-4 pr-lg-5 pl-lg-5 pr-2 pl-2">
                                <h2 className="h2-responsive text-center pt-5 pb-5">
                                    Our latest property for sale
                                </h2>
                                <div className="pb-5 pt-2">
                                    <ProjectCardContainer toggleProject={this.toggleProject} />
                                </div>
                                <div className="text-center">
                                    <MDBBtn color="white" outline onClick={() => this.props.history.push('/property-for-sale')}>All current listings</MDBBtn>
                                </div>
                            </div>
                        </div>

                        <Parallax
                            bgImage={require('../../assets/img/ss2.jpg')}
                            bgImageAlt="silverstar developments"
                            strength={200}
                            className="page-header header-filter clear-filter dark-filter white-text mt-2 mb-2"
                        >
                            <MDBContainer className="text-left">
                                <div style={{ zIndex: '2', position: "relative" }}>
                                    <h2 className="h2-responsive">Leading edge construction methods and unique designs.</h2>
                                    <h6 className="h5-responsive mt-3 mb-lg-4">Our homes cover all budgets and all styles of living, but they’re all linked by a common theme: bright, light-filled spaces, great, practical liveability, and stylish contemporary appeal.</h6>
                                    <MDBBtn color="white" outline onClick={() => scrollTo('subscribe')}>Subscribe to our newsletter</MDBBtn>
                                </div>
                            </MDBContainer>

                            <div style={{ minHeight: '400px' }} />
                        </Parallax>

                        <div id="sold-property" className="default-bg pt-5 pb-5">
                            <div className="mb-4 pr-lg-5 pl-lg-5 pr-2 pl-2">
                                <h2 className="h2-responsive text-center pt-5 pb-5">
                                    Our recently sold property
                                </h2>
                                <div className="pb-5 pt-2">
                                    <ProjectCardContainerSold toggleProject={this.toggleProject} />
                                </div>
                                <div className=" text-center">
                                    <MDBBtn color="white" outline onClick={() => this.props.history.push('/sold-property')}>All sold property</MDBBtn>
                                </div>
                            </div>
                        </div>

                    </div>

                    <BrowserView>
                        <div style={{ minHeight: "300px" }}>
                            <div className="pt-5 pb-5 white-text">
                                <MDBContainer>
                                    <MDBRow>
                                        <MDBContainer className="mt-5 mb-5 text-center">
                                            <MDBCol md="12">
                                                <h2 className="h2-responsive mb-4 mt-5">We want to sell you a home for the <b className="grey-text">right</b> reasons.</h2>
                                                <h6 className="h4-responsive text-left mb-5" style={{ lineHeight: "33px" }}> <MDBIcon icon="quote-left" size="1x" className="mr-2" /> Because the home suits your needs perfectly, without compromise, without breaking your budget. Because you simply fall in love with the design, and what it will mean for your lifestyle and those you love.</h6>
                                            </MDBCol>
                                        </MDBContainer>
                                    </MDBRow>
                                </MDBContainer>
                            </div>
                        </div>
                    </BrowserView>

                    <MobileView>
                        <div style={{ minHeight: "300px" }}
                            className="page-header header-filter clear-filter dark-filter white-text"
                        >
                            <div className="pt-5 pb-5 white-text home-banner">
                                <MDBContainer>
                                    <MDBRow>
                                        <MDBContainer className="mt-5 mb-5 text-center">
                                            <MDBCol md="12">
                                                <h2 className="h2-responsive mb-4 mt-5">We want to sell you a home for the right reasons.</h2>
                                                <h6 className="h4-responsive text-left mb-5" style={{ lineHeight: "33px" }}> <MDBIcon icon="quote-left" size="1x" className="mr-2" /> Because the home suits your needs perfectly, without compromise, without breaking your budget. Because you simply fall in love with the design, and what it will mean for your lifestyle and those you love.</h6>
                                            </MDBCol>
                                        </MDBContainer>
                                    </MDBRow>
                                </MDBContainer>
                            </div>
                        </div>
                    </MobileView>

                    <div style={{ backgroundColor: "white" }}>

                        <div id="videos" className="secondary-bg pt-5 pb-5">
                            <div className="mb-4 pr-lg-5 pl-lg-5 pr-2 pl-2 text-center">
                                <div className="h1-responsive font-Yellowtail mt-3 mb-4 text-center">Virtual tours</div>
                                <div className="pb-5 text-center">
                                    <VideoCardContainer />
                                </div>
                                <MDBBtn color="white" className="mt-2" outline onClick={() => this.props.history.push('/virtual-tours')}>All virtual tours</MDBBtn>
                            </div>
                        </div>

                        <Parallax
                            bgImage={require('../../assets/img/ss-bath.jpg')}
                            bgImageAlt="silverstar developments"
                            strength={200}
                            className="page-header header-filter clear-filter dark-filter white-text mt-2"
                        >
                            <MDBContainer className="text-left">
                                <div style={{ zIndex: '2', position: "relative" }}>
                                    <h2 className="h2-responsive">We get to know our clients needs, first.</h2>
                                    <h6 className="h5-responsive mt-3 mb-lg-4">Our approach is simple, personal contact from the start of a project to the finishing touches with all our clients. This enables us to cater for personal preferences and style in every design.</h6>
                                    <MDBBtn color="white" outline onClick={() => this.props.history.push('/contact-us')}>Contact Us</MDBBtn>
                                </div>
                            </MDBContainer>

                            <div style={{ minHeight: '400px' }} />
                        </Parallax>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(Home);